@font-face {
  font-family: heb;
  src: url("../assets//fonts/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: logo;
  src: url("../assets//fonts/MochiyPopPOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: ralewayLight;
  src: url("../assets//fonts/raleway/Raleway-Light.ttf") format("truetype");
}

@font-face {
  font-family: openSansRegular;
  src: url("../assets//fonts/Alef/Alef-Regular.ttf") format("truetype");
}

@font-face {
  font-family: openSansMedium;
  src: url("../assets//fonts/Alef/Alef-Bold.ttf") format("truetype");
}

.animate__animated.animate__fadeInLeftBig {
  --animate-duration: 3s;
}

@font-face {
  font-family: aviv;
  src: url("../assets/producers/aviv/HoneyBear-8MegB.ttf") format("truetype");
}

body {
  margin: 0;
  background: radial-gradient(circle, #eeeeee 0%, #a5a5a5b6 100%);
}

.css-1pahdxg-control:hover {
  border-color: #ff2f00ee !important;
  box-shadow: 0 0 0 1px #ff2f00ee !important;
}

.css-1pahdxg-control {
  border-color: #ff2f00ee !important;
  box-shadow: 0 0 0 1px #ff2f00ee !important;
}

.frjscc {
  min-height: 5.5vh !important;
}

.css-yk16xz-control {
  min-height: 5vh !important;
}

input[type="time"]:focus {
  outline: none !important;
}

.App {
  direction: rtl;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  color: #3a3c3f;
  font-family: heb !important;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  background: #f2f2f2;
}
