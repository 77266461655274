body {
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth !important;
}

::selection {
  background: #4a393f6b;
  color: white;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

tr:hover {
  background-color: #a09e9e34 !important;
  transition: 200ms;
  /* color: #f2f2f2d2; */
}

.swiper {
  width: 90%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.sss a {
  text-decoration: none !important;
}