
abbr[data-bs-original-title], abbr[title] {
    text-decoration: none;
    font-size: 14px;
}

.react-calendar__navigation button:disabled {
    background-color: white !important;
    border-radius: 5px;
}
.react-calendar__navigation button {
    border-radius: 5px;
    background: white !important;
    border: none;
    /* box-shadow: 2px 4px 10px #ccc; */
}

.react-calendar button {
    position: relative;
}

.react-calendar{
    
    width: 100% !important;
    box-shadow: 2px 4px 10px #ccc;
    border: none !important;
    border-radius: 5px;
    padding-bottom: 25px;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    border-radius: 5px;
}

.react-calendar__tile--now {
    background: #ffbf00 !important;
    border-radius: 5px;
}

.react-calendar__tile--active {
    border-radius: 5px;
}