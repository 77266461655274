@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
body {
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth !important;
}

::selection {
  background: #4a393f6b;
  color: white;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

tr:hover {
  background-color: #a09e9e34 !important;
  transition: 200ms;
  /* color: #f2f2f2d2; */
}

.swiper {
  width: 90%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.sss a {
  text-decoration: none !important;
}
@font-face {
  font-family: heb;
  src: url(/static/media/Rubik-Regular.da39336c.ttf) format("truetype");
}

@font-face {
  font-family: logo;
  src: url(/static/media/MochiyPopPOne-Regular.7ae691c1.ttf) format("truetype");
}

@font-face {
  font-family: ralewayLight;
  src: url(/static/media/Raleway-Light.b1f782b7.ttf) format("truetype");
}

@font-face {
  font-family: openSansRegular;
  src: url(/static/media/Alef-Regular.87215bd3.ttf) format("truetype");
}

@font-face {
  font-family: openSansMedium;
  src: url(/static/media/Alef-Bold.658af1d8.ttf) format("truetype");
}

.animate__animated.animate__fadeInLeftBig {
  --animate-duration: 3s;
}

@font-face {
  font-family: aviv;
  src: url(/static/media/HoneyBear-8MegB.e74d8b7b.ttf) format("truetype");
}

body {
  margin: 0;
  background: radial-gradient(circle, #eeeeee 0%, #a5a5a5b6 100%);
}

.css-1pahdxg-control:hover {
  border-color: #ff2f00ee !important;
  box-shadow: 0 0 0 1px #ff2f00ee !important;
}

.css-1pahdxg-control {
  border-color: #ff2f00ee !important;
  box-shadow: 0 0 0 1px #ff2f00ee !important;
}

.frjscc {
  min-height: 5.5vh !important;
}

.css-yk16xz-control {
  min-height: 5vh !important;
}

input[type="time"]:focus {
  outline: none !important;
}

.App {
  direction: rtl;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  color: #3a3c3f;
  font-family: heb !important;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  background: #f2f2f2;
}


abbr[data-bs-original-title], abbr[title] {
    text-decoration: none;
    font-size: 14px;
}

.react-calendar__navigation button:disabled {
    background-color: white !important;
    border-radius: 5px;
}
.react-calendar__navigation button {
    border-radius: 5px;
    background: white !important;
    border: none;
}

.react-calendar button {
    position: relative;
}

.react-calendar{
    
    width: 100% !important;
    box-shadow: 2px 4px 10px #ccc;
    border: none !important;
    border-radius: 5px;
    padding-bottom: 25px;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    border-radius: 5px;
}

.react-calendar__tile--now {
    background: #ffbf00 !important;
    border-radius: 5px;
}

.react-calendar__tile--active {
    border-radius: 5px;
}
.body {
    background-color: #e3e7f3;
    height: 100vh;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    direction: rtl;
}

.menu {
    padding: 5px;
    width: -webkit-max-content;
    width: max-content;
    display: flex;

    background: linear-gradient(135deg, #3a3475, rgba(81, 71, 171, 0.549));
    box-shadow: 0 2px 12px 0 rgba(147, 139, 224, 0.549);
    border-radius: 4px;
    color: rgb(67, 66, 66);

}

.fa {
    font-size: 24px;
    position: relative;
    top: 2px;
    opacity: 0.7;
    color: white !important;
}

.fa-cog {
    position: relative;
    right: 3px;
}

.menu_text {
    padding-right: 6px;
    height: 30px;
}

.menu_box {
    background: #000;
    box-shadow: 0 2px 12px 0 rgba(91, 41, 191, 0.5);
    border-radius: 4px;
    position: absolute;
    bottom: calc(100% + 14px);
    right: 0;
    width: -webkit-max-content;
    width: max-content;
    padding: 10px;
    opacity: 0;
    -webkit-transform: translateY(100%) translateX(-30%) scale(0.1);
    transform: translateY(100%) translateX(-30%) scale(0.1);
    transition: all 0.2s;
}

.menu_box:after {
    content: "";
    position: absolute;
    right: 20px;
    bottom: -20px;
    border: 10px solid transparent;
    border-top: 10px solid #000;
}

.menu_list {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.7;
}

.menu_label {
    position: relative;
    cursor: pointer;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #fff;
    width: 44px;
    overflow: hidden;
    display: flex;
    padding: 0 10px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    transition: all 0.3s;
}

.menu_label:hover .fa {
    opacity: 1;
}

.menu_text {
    opacity: 0;
    transition: all 0.3s;
}

.menu_input[type=radio] {
    display: none;
}

.menu_input[type=radio]:checked+.menu_label {
    background-color: rgba(234, 17, 17, 0.3);
    width: 100px;
}

.menu_input_vip[type=radio]:checked+.menu_label {
    background-color: rgba(255, 255, 255, 0.996);
    width: 72px;
}

.menu_input_suggested[type=radio]:checked+.menu_label {
    background-color: rgba(255, 255, 255, 0.3);
    width: 89px;
}

.menu_input_basic[type=radio]:checked+.menu_label {
    background-color: rgba(255, 255, 255, 0.3);
    width: 84px;
}

.menu_input[type=radio]:checked+.menu_label .fa {
    opacity: 1;
}

.menu_input[type=radio]:checked+.menu_label .menu_text {
    opacity: 1;
}

.menu_input[type=radio]:checked+.menu_label+.menu_box {
    opacity: 1;
    -webkit-transform: translateY(0) translateX(0) scale(1);
    transform: translateY(0) translateX(0) scale(1);
}

.rabbit {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 3;
    fill: #fff;
}
.scale {
    transition: all 200ms;
}

.scale:hover {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    transition: all 200ms;
}

abbr[data-bs-original-title], abbr[title] {
    text-decoration: none;
    font-size: 14px;
}

.react-calendar__navigation button:disabled {
    background-color: white !important;
    border-radius: 5px;
}
.react-calendar__navigation button {
    border-radius: 5px;
    background: white !important;
    border: none;
    /* box-shadow: 2px 4px 10px #ccc; */
}

.react-calendar button {
    position: relative;
}

.react-calendar{
    
    width: 100% !important;
    box-shadow: 2px 4px 10px #ccc;
    border: none !important;
    border-radius: 5px;
    padding-bottom: 25px;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    border-radius: 5px;
}

.react-calendar__tile--now {
    background: #ffbf00 !important;
    border-radius: 5px;
}

.react-calendar__tile--active {
    border-radius: 5px;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: gray !important;
    font-size: 16px !important;
}
.css-88nv5c-MuiInputBase-root-MuiInput-root-MuiSelect-root:after{
    border-bottom: 2px solid rgba(50, 50, 50, 0.5) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #fff !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: gray !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    z-index: 0 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
    direction: rtl !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #3A3C3F !important;
}
/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline */

.flexbox-fix{
    padding-top:10px !important;
}
.css-1agwao2-MuiFormControl-root{
    margin: 0px !important
}
.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input{
    padding: 2px 0 0 !important;
}

@font-face {
    font-family: heb;
    src: url(/static/media/Rubik-Regular.da39336c.ttf) format("truetype");
  }
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 16px !important;
    font-family: heb !important;
}
@media only screen and (max-width:768px){
    .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
      font-size: 14px !important;
      font-family: heb !important;
    }
  }
.Demo__some-network__share-button div {
    background: rgba(255, 255, 255, 0.1) !important;
}
input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  margin: 5vh auto;
  margin-bottom: 0;
  padding: 6px 12px;
  cursor: pointer;
}

.mjmQf {
  margin: 0 0 !important;
  padding-left: 4px;
}

.fPTswH {
  margin: 0 0 !important;
  padding-left: 4px;
}

.dropdown-menu {
  max-width: 40px !important;
}

.dropdown-menu .show {
  max-width: 40px !important;
}

.dropdown-menu.show {
  max-width: 40px !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 8px !important;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 14px;
  }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 16px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 20px;
  right: 26px;
  top: 26px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 5px !important;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  min-width: 20%;
}

/* General sidebar styles */
.bm-menu {
  font-size: 19px;
  overflow: hidden !important;
}

@media only screen and (max-width: 768px) {
  .bm-menu {
    font-size: 17px;
    overflow: hidden !important;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #f0f0f0;
  /* padding: 1.8em; */
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* .bm-item-list:hover {
    color: rgb(255, 255, 255) !important;
  } */

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item:hover {
  cursor: pointer;
  color: white !important;
}

.menu-item-black:hover {
  cursor: pointer;
  color: black !important;
}

.menu-item img {
  margin-right: 15px !important;
}

.pp {
    width: 100%;
    height: 100%;
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.cropper {
    position: relative;
    height: 50vh;
}

.viewer {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.output {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.output img {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform-origin: top left;
            transform-origin: top left;
}
