.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: gray !important;
    font-size: 16px !important;
}
.css-88nv5c-MuiInputBase-root-MuiInput-root-MuiSelect-root:after{
    border-bottom: 2px solid rgba(50, 50, 50, 0.5) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #fff !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: gray !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    z-index: 0 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
    direction: rtl !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #3A3C3F !important;
}
/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline */

.flexbox-fix{
    padding-top:10px !important;
}
.css-1agwao2-MuiFormControl-root{
    margin: 0px !important
}
.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input{
    padding: 2px 0 0 !important;
}

@font-face {
    font-family: heb;
    src: url("../../../assets//fonts/Rubik-Regular.ttf") format("truetype");
  }
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 16px !important;
    font-family: heb !important;
}
@media only screen and (max-width:768px){
    .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
      font-size: 14px !important;
      font-family: heb !important;
    }
  }