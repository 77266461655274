@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);

.body {
    background-color: #e3e7f3;
    height: 100vh;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    direction: rtl;
}

.menu {
    padding: 5px;
    width: max-content;
    display: flex;

    background: linear-gradient(135deg, #3a3475, rgba(81, 71, 171, 0.549));
    box-shadow: 0 2px 12px 0 rgba(147, 139, 224, 0.549);
    border-radius: 4px;
    color: rgb(67, 66, 66);

}

.fa {
    font-size: 24px;
    position: relative;
    top: 2px;
    opacity: 0.7;
    color: white !important;
}

.fa-cog {
    position: relative;
    right: 3px;
}

.menu_text {
    padding-right: 6px;
    height: 30px;
}

.menu_box {
    background: #000;
    box-shadow: 0 2px 12px 0 rgba(91, 41, 191, 0.5);
    border-radius: 4px;
    position: absolute;
    bottom: calc(100% + 14px);
    right: 0;
    width: max-content;
    padding: 10px;
    opacity: 0;
    -moz-transform: translateY(100%) translateX(-30%) scale(0.1);
    -ms-transform: translateY(100%) translateX(-30%) scale(0.1);
    -webkit-transform: translateY(100%) translateX(-30%) scale(0.1);
    transform: translateY(100%) translateX(-30%) scale(0.1);
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.menu_box:after {
    content: "";
    position: absolute;
    right: 20px;
    bottom: -20px;
    border: 10px solid transparent;
    border-top: 10px solid #000;
}

.menu_list {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.7;
}

.menu_label {
    position: relative;
    cursor: pointer;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #fff;
    width: 44px;
    overflow: hidden;
    display: flex;
    padding: 0 10px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.menu_label:hover .fa {
    opacity: 1;
}

.menu_text {
    opacity: 0;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.menu_input[type=radio] {
    display: none;
}

.menu_input[type=radio]:checked+.menu_label {
    background-color: rgba(234, 17, 17, 0.3);
    width: 100px;
}

.menu_input_vip[type=radio]:checked+.menu_label {
    background-color: rgba(255, 255, 255, 0.996);
    width: 72px;
}

.menu_input_suggested[type=radio]:checked+.menu_label {
    background-color: rgba(255, 255, 255, 0.3);
    width: 89px;
}

.menu_input_basic[type=radio]:checked+.menu_label {
    background-color: rgba(255, 255, 255, 0.3);
    width: 84px;
}

.menu_input[type=radio]:checked+.menu_label .fa {
    opacity: 1;
}

.menu_input[type=radio]:checked+.menu_label .menu_text {
    opacity: 1;
}

.menu_input[type=radio]:checked+.menu_label+.menu_box {
    opacity: 1;
    -moz-transform: translateY(0) translateX(0) scale(1);
    -ms-transform: translateY(0) translateX(0) scale(1);
    -webkit-transform: translateY(0) translateX(0) scale(1);
    transform: translateY(0) translateX(0) scale(1);
}

.rabbit {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 3;
    fill: #fff;
}