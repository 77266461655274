.pp {
    width: 100%;
    height: 100%;
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.cropper {
    position: relative;
    height: 50vh;
}

.viewer {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.output {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.output img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
}